import './App.css';

import Nav from "./Nav";
import About from "./About";
import Shop from "./Shop";
import Cxr from "./Cxr"
import ItemDetail from "./ItemDetail";

import {Route, BrowserRouter as Router, Switch, HashRouter} from 'react-router-dom'
import Users from "./Users";

function App() {
  return (
      <div className="App">
        <HashRouter >
            <Nav/>
            <Switch>
            {/*<Route path="/" exact component={Home}/>*/}
            {/*<Route path="/about" component={About}/>*/}
            <Route path="/xray" exact component={Shop}/>
            <Route path="/xray/:id" component={ItemDetail}/>
            {/*<Route path="/cxr" component={Cxr}/>*/}
            {/*<Route path="/users" component={Users}/>*/}
            </Switch>
        </HashRouter>

      </div>

  );
}

const Home = () => (
    <div>
        <h1>Home page</h1>
    </div>
);
export default App;
