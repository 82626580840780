import './App.css';
import {useEffect, useState} from "react";
import {Link} from 'react-router-dom'

function Shop() {
    useEffect(() =>{
        fetchItems();
    },[]);


    const [items, setItems] = useState([])
    const fetchItems = async () =>{
        // const data  = await fetch(
        //             'https://fortnite-api.theapinetwork.com/upcoming/get'
        // );

        const data  = await fetch(
            '/imagelist.json'
        );

        const items = await data.json();
        console.log(items.data)
        setItems(items.data)

    }
    return (
        <div>
            {
                items.map(item => (
                    <h3 key={item.itemId}>
                        <Link to={`/xray/${item.itemId}`}>
                            {item.item.name}
                        </Link>
                    </h3>
                ))
            }
        </div>
    );
}

export default Shop;