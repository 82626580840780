import './App.css';
import { useEffect, useState} from "react";
import React,{Suspense} from "react";
import getImageItemAt from "./GetItem";
const CxrLive = React.lazy(() => import("./CxrLive"))
const divStyle = {
    width: "512px",
    height: "512px",
    display:"flex",
    color: "white"
};

function ItemDetail({match}) {

    useEffect(() =>{
        fetchItem();
    },[]);

    const [item, setItem] = useState({
        data:{item:{images:{}}}
    })

    const fetchItem = async () =>{
        // const fetchItem = await fetch(`https://fortnite-api.theapinetwork.com/item/get?id=${match.params.id}`)
        // const item = await fetchItem.json();
        // setItem(item)
        const item = getImageItemAt(match.params.id)
        setItem(item);
    }

    return (
        <div>
            <h1>Image at {item.data.item.images.icon}</h1>
            {/*<h1>{item.data.item.name}</h1>*/}
            {/*<h6>{item.data.item.images.icon}</h6>*/}
            {/*<img src={item.data.item.images.icon}/>*/}
            {
                item ? (
                    <Suspense fallback ={<div>Loading ...</div>}>
                        <h3>CornerstoneJS</h3>
                        <table>

                               <tr> <td>Dynamic viewer (Scroll to +/- zoom)</td><td>Static Image</td></tr>

                            <tr>
                                <td>
                                    <CxrLive img={item.data.item.images.icon} />
                                </td>
                                <td>
                                    <img style={divStyle} src={item.data.item.images.icon1}/>
                                </td>
                            </tr>
                        </table>

                    </Suspense>
                ): null
            }

        </div>
    );
}

export default ItemDetail;