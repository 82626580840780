const arr = [
    {
        data:{
        "itemId": "1",
        "item": {
            "name": "Chest 1",
            "images": {
                "icon1": "https://davidlyell.com/viewer/images/a.png",
                "icon": "https://davidlyell.com/viewer/images/b.png"
            }
        }
        }
    },
    {
        data:{
        "itemId": "2",
        "item": {
            "name": "Chest 2",
            "images": {
                "icon1": "https://davidlyell.com/viewer/images/c.png",
                "icon": "https://davidlyell.com/viewer/images/d.png"
            }
        }
        }
    }
];

function getImageItemAt(i){
        return arr[i];
}

export default getImageItemAt