import './App.css';
import {Link} from "react-router-dom";

function Nav() {
    const navStyle ={
        color:'white'
    };
    return (
        <div>
          <nav>
              {/*<h3>Logo</h3>*/}
              <ul className="nav-links">

                {/*<Link style={navStyle} to="/about">*/}
                {/*    <li>About</li>*/}
                {/*</Link>*/}
                  <Link style={navStyle} to="/xray">
                      <li>xrays</li>
                  </Link>
                  {/*<Link style={navStyle} to="/cxr">*/}
                  {/*    <li>Cxr</li>*/}
                  {/*</Link>*/}
                  {/*<Link style={navStyle} to="/users">*/}
                  {/*    <li>Users</li>*/}
                  {/*</Link>*/}

              </ul>
          </nav>
        </div>
    );
}

export default Nav;